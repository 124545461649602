import * as React from "react";
import bcImage from '../../images/birthcontrol.gif';
import bcImage2 from '../../images/birthcontrol2.png';
import bcImage3 from '../../images/birthcontrol3.png';
import Project from '../../Layouts/project';



// markup
const BC = () => {
  return (
    <Project 
      title="Searching r/birthcontrol" 
      images={[bcImage, bcImage2, bcImage3]} 
      link="https://reddit-birthcontrol.netlify.app/"
      description="My Master's project: a visualization based tool for navigating posts from the birth control subreddit, built with D3, React, Node.js, and MongoDB. I used R to analyze the posts and calculate word associations." />)
}

export default BC
